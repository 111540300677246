import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import NavButtons from 'components/Downloads/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How to verify the checksum of a downloaded file",
  "path": "/Downloads/Security/",
  "dateChanged": "2019-10-16",
  "author": "Mike Polinowski",
  "excerpt": "For our software we display the checksum for the file that you wish to download. Depending on what operating system you are using, once you have downloaded the required file you can compute a hash of it.",
  "image": "../D_SearchThumb.png",
  "social": "/images/Search/D_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Download Area' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/D_Downloads_SearchThumb.png' twitter='/images/Search/D_Downloads_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Security/' locationFR='/fr/Downloads/Security/' crumbLabel="Check Sum" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "how-to-verify-the-checksum-of-a-downloaded-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-verify-the-checksum-of-a-downloaded-file",
        "aria-label": "how to verify the checksum of a downloaded file permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to verify the checksum of a downloaded file`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#software-installation-wizard"
        }}>{`Software Installation Wizard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instructions"
        }}>{`Instructions`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#quick-installation"
            }}>{`Quick Installation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-software-updates"
        }}>{`Camera software updates`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#firmware"
            }}>{`Firmware`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#download"
                }}>{`Download`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#windows"
        }}>{`Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux"
        }}>{`Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#macos"
        }}>{`macOS`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`For our software we display the checksum for the file that you wish to download. Can run a check once you downloaded the file, to make sure that it's content was not compromised. Depending on what operating system you are using, once you have downloaded the required file you can compute a hash of it.`}</p>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <p>{`The SHA265 hash can be found next to the download file in our download area:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/32007770819d0fdac868bd74b58b519a/42d54/INSTAR_Sha265_Crypto_Hash_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.086956521739133%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAXElEQVQI102IBw6AMAwD+/+XsqlpCGQUoVLW6WTZDgDatun6HgBiZGY1u62YfvM9L4KITHEdZkpEyqyJNCVZliIgtV+l/ETufjyEnB3kI3zdxERsr7mXfP3N/OME+zyuK4KG1VAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32007770819d0fdac868bd74b58b519a/e4706/INSTAR_Sha265_Crypto_Hash_01.avif 230w", "/en/static/32007770819d0fdac868bd74b58b519a/d1af7/INSTAR_Sha265_Crypto_Hash_01.avif 460w", "/en/static/32007770819d0fdac868bd74b58b519a/1f8f9/INSTAR_Sha265_Crypto_Hash_01.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/32007770819d0fdac868bd74b58b519a/a0b58/INSTAR_Sha265_Crypto_Hash_01.webp 230w", "/en/static/32007770819d0fdac868bd74b58b519a/bc10c/INSTAR_Sha265_Crypto_Hash_01.webp 460w", "/en/static/32007770819d0fdac868bd74b58b519a/41e9e/INSTAR_Sha265_Crypto_Hash_01.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32007770819d0fdac868bd74b58b519a/81c8e/INSTAR_Sha265_Crypto_Hash_01.png 230w", "/en/static/32007770819d0fdac868bd74b58b519a/08a84/INSTAR_Sha265_Crypto_Hash_01.png 460w", "/en/static/32007770819d0fdac868bd74b58b519a/42d54/INSTAR_Sha265_Crypto_Hash_01.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/32007770819d0fdac868bd74b58b519a/42d54/INSTAR_Sha265_Crypto_Hash_01.png",
              "alt": "INSTAR SHA265 Security Hash for Download",
              "title": "INSTAR SHA265 Security Hash for Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open your Powershell or Command Shell to run the following command - make sure that you are inside the directory that contains the downloaded file and replace `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`filename`}</code>{` in the command with the name of the file you want to check:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`CertUtil -hashfile filename SHA256`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/728299a9bde7d0c4e06c114525e9ddd4/914c7/INSTAR_Sha265_Crypto_Hash_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.043478260869563%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAu0lEQVQY03XOyw6CMBBAUTa0wgxFpQhNYQoGRGp0YeMrunCh//9LBh8r9exvcr3KGEOmqmoi0lonMhl9cD5ijDP+5jPm+z574pxDGHhlWSqliEgplUyn4/FEJjKVg1gIAEAY4BcA8HKtVVloIpllYRRhLMIIA0SMYxAiQBRP+It3WC53XXfo+5Nd7bv2aBerorBau6bdmKpOZ2k6fPyOL9Ze15vbdnt37tz3rp67er5vGpvnNsubP9krfgCqeRfwWb4DMwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/728299a9bde7d0c4e06c114525e9ddd4/e4706/INSTAR_Sha265_Crypto_Hash_02.avif 230w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/d1af7/INSTAR_Sha265_Crypto_Hash_02.avif 460w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/7f308/INSTAR_Sha265_Crypto_Hash_02.avif 920w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/6a69c/INSTAR_Sha265_Crypto_Hash_02.avif 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/728299a9bde7d0c4e06c114525e9ddd4/a0b58/INSTAR_Sha265_Crypto_Hash_02.webp 230w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/bc10c/INSTAR_Sha265_Crypto_Hash_02.webp 460w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/966d8/INSTAR_Sha265_Crypto_Hash_02.webp 920w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/0e613/INSTAR_Sha265_Crypto_Hash_02.webp 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/728299a9bde7d0c4e06c114525e9ddd4/81c8e/INSTAR_Sha265_Crypto_Hash_02.png 230w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/08a84/INSTAR_Sha265_Crypto_Hash_02.png 460w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/c0255/INSTAR_Sha265_Crypto_Hash_02.png 920w", "/en/static/728299a9bde7d0c4e06c114525e9ddd4/914c7/INSTAR_Sha265_Crypto_Hash_02.png 978w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/728299a9bde7d0c4e06c114525e9ddd4/c0255/INSTAR_Sha265_Crypto_Hash_02.png",
              "alt": "INSTAR SHA265 Security Hash for Download",
              "title": "INSTAR SHA265 Security Hash for Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Linux`}</h2>
    <p>{`The same can be done under LINUX with the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`sha256sum filename`}</code></pre></div>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <p>{`And under macOS use the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`shasum -a `}<span parentName="code" {...{
            "className": "token number"
          }}>{`256`}</span>{` filename`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      